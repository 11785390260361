import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Hero from '../components/EquipmentTabs/Hero'
import Footer from '../components/Footer'
import Freequote from '../components/Freequote'
import Layout from '../components/Layout'
import Location from '../components/Location'
import SEO from '../components/seo'

const Eform = () => {

  const data = useStaticQuery(
    graphql`
      query {
        servicesHeader: file(relativePath: { eq: "e-forms.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        truckLoad: file(relativePath: { eq: "bill-of-lading.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        headerTruck: file(relativePath: { eq: "credit-application.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tradeShow: file(relativePath: { eq: "credit-card-authentication.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const servicesheader = data.servicesHeader.childImageSharp.fluid
  const truckload = data.truckLoad.childImageSharp.fluid
  const headertruck = data.headerTruck.childImageSharp.fluid
  const tradeshow = data.tradeShow.childImageSharp.fluid

  const forms = [
    {
      image: truckload,
      altText: 'Bill of Lading',
      title: 'Bill of Lading',
      url: '/bill-of-lading/'
    },
    {
      image: headertruck,
      altText: 'Credit Application',
      title: 'Credit Application',
      url: '/credit-application/'
    },
    {
      image: tradeshow,
      altText: 'Credit Card Authentication',
      title: 'Credit Card Authentication',
      url: '/credit-card-auth/'
    }
  ]

  return (
    <Layout>
      <SEO title="Services | RoadLinx Shipping Company" />
      <Hero
        image={servicesheader}
        type="primary"
      />
      <section>
        <Container fluid>
          <Row>
            <Col className="services__col">
              <h1 className="services__heading">
                e-Forms
              </h1>
            </Col>
          </Row>
          <Row>
            {forms?.map((form, index) => {
              return (
                <Col key={index} className="services__sub-col" xl={4}>
                  <Link to={form?.url} className="services__wrapper">
                    <Img
                      fluid={
                        form?.image
                      }
                      className="services__sec-image"
                      alt={form?.altText}
                    />
                    <div>{form?.title}</div>
                  </Link>
                </Col>
              )
            })}
          </Row>
        </Container>
      </section>
      <Freequote />
      <Location />
      <Footer />
    </Layout>
  )
}

export default Eform
